import React, { memo } from 'react';
import HeaderRow from './HeaderRow';
import DateHeaderRow from './DateHeaderRow';
import TimesheetRow from './TimesheetRow';
import { DataRow } from './dtos/TimesheetRow.dto';
import { HeaderData } from './dtos/HeaderRow.dto';
import { reportHeaderStyle, headerRow1Style, baseHeaderCellStyle, headerRow2Style } from './styles/styles';

interface Form421AProps {
    header: HeaderData;
    timesheets: DataRow[];
    isDocusign: boolean;
}

function formatDate(dateString: string): string | null {
    if (!dateString) return null;

    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
}

function chunkArray(array: DataRow[], size: number) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
}

const Form421A: React.FC<Form421AProps> = memo(({ header, timesheets, isDocusign }) => {
    const pages = chunkArray(timesheets, 7);
    return (
        <div className="form-container">
            <form style={{ width: '99%' }}>
                <style>
                    {`
                    .form-container {
                        transform: scale(0.7);
                        transform-origin: top left;
                        width: 142%;
                        overflow-x: hidden;
                    }

                    .signature-container {
                        display: flex;
                        justify-content: center; /* Center horizontally */
                        align-items: center;
                        margin-right: 20px; /* Adjust this margin if needed */
                        padding: 10px; /* Adjust the padding if needed */
                    }
                    
                    .date-signed {
                        margin-right: 200px; /* Space between date-signed and signature */
                    }
                    
                    .signature {
                        margin-left: 0px; /* Adjust this margin if needed */
                    }

                    @media print {
                        .page {
                            page-break-after: always;
                        }
                        .header, .timesheet {
                            page-break-inside: avoid;
                        }
                    }
                    `}
                </style>
                {pages.map((page, pageIndex) => (
                    <div key={pageIndex} className="page" style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <HeaderRow
                            rows={[
                                {
                                    type: 'title',
                                    label: 'THE CITY OF NEW YORK - OFFICE OF THE COMPTROLLER - BUREAU OF LABOR LAW',
                                    labelStyle: { fontWeight: 'bold', fontSize: '18px', textAlign: 'center', color: 'white', backgroundColor: 'black', height: '100%', alignItems: 'center' },
                                    columnStyle: { border: 'black solid 1px', width: '100%' },
                                },
                            ]}
                            rowStyle={reportHeaderStyle}
                        />

                        <HeaderRow
                            rows={[
                                {
                                    label: 'Employer Name',
                                    type: 'value',
                                    value: header.employerName || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'CERTIFIED PAYROLL REPORT',
                                    type: 'title',
                                    labelStyle: { ...baseHeaderCellStyle, fontWeight: 'bold', fontSize: '28px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' },
                                },
                                {
                                    label: 'PLA',
                                    type: 'value',
                                    value: header.pla || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'Payroll #',
                                    type: 'value',
                                    value: header.payrollNumber || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'Week Ending Date',
                                    type: 'value',
                                    value: formatDate(String(header.weekEndingDate)) || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                            ]}
                            rowStyle={headerRow1Style}
                        />

                        <HeaderRow
                            rows={[
                                {
                                    label: 'Employer Address',
                                    type: 'value',
                                    value: header.employerAddress || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'Employer Email Address',
                                    type: 'value',
                                    value: header.employerEmail || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'Employer Phone #',
                                    type: 'value',
                                    value: header.employerPhone || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'Employer Tax ID',
                                    type: 'value',
                                    value: header.employerTaxId || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'Project Name',
                                    type: 'value',
                                    value: header.projectName || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                            ]}
                            rowStyle={headerRow2Style}
                        />

                        <HeaderRow
                            rows={[
                                {
                                    label: 'Name of Prime Contrator, Building Owner or Utility',
                                    type: 'value',
                                    value: header.buildingOwner || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'Contract Registration #',
                                    type: 'value',
                                    value: header.contractRegistration || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'Agency',
                                    type: 'value',
                                    value: header.agency || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'Agency Pin #',
                                    type: 'value',
                                    value: header.agencyPin || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                                {
                                    label: 'Project or Building Address',
                                    type: 'value',
                                    value: header.buildingAddress || 'Loading...',
                                    valueStyle: { fontWeight: 'bold', fontSize: '14px' },
                                    labelStyle: { fontSize: '12px' },
                                    columnStyle: baseHeaderCellStyle,
                                },
                            ]}
                            rowStyle={headerRow2Style}
                        />

                        <DateHeaderRow endDate={String(header.weekEndingDate)} />

                        {page.map((data, rowIndex) => (
                            <TimesheetRow key={rowIndex} data={data} />
                        ))}
                        <div className="signature-container">
                            {isDocusign && (
                                <>
                                    <div className="date-signed">/d{pageIndex + 2}/</div>
                                    <div className="signature">/s{pageIndex + 2}/</div>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </form>
        </div>
    );
});

// TODO: NEED TO UPDATE SIGNATURE LOGIC TO ACCOUNT FOR SIGNATURES IN DISCLAIMER FILE... RIGHT NOW JUST INCREMENTED BY 2 INSTEAD OF 1
export default Form421A;
