import React, { memo } from 'react';
import { 
    tableRowContainer, 
    tableRowSection1Style, 
    verticalStackCellStyle, 
    verticalStackCellStyle2 ,
    tableRowSection2Style,
    dateHeaderStyle,
    weekdayStyle,
} from './styles/styles'

import { 
    WeekDate, 
    DateHeaderProps,
} from './dtos/DateHeaderRow.dto'
  
const generateWeekDates = (endDate: string): WeekDate[] => {
    const dates: WeekDate[] = [];
    const currentDate = new Date(endDate);
    for (let i = 6; i >= 0; i--) {
        const date = new Date(currentDate);
        date.setDate(date.getDate() - i);
        const [weekday, monthDay] = date.toLocaleDateString('en-US', { weekday: 'short', month: 'numeric', day: 'numeric' }).split(' ');
        dates.push({
        weekday,
        date: `${monthDay}`
        });
    }

    return dates;
};

const DateHeader: React.FC<DateHeaderProps> = ({ endDate }) => {
    const weekDates = generateWeekDates(endDate);
    return (
        <div style={ tableRowContainer }>
            <div style={ tableRowSection1Style }>
                <div style={ { ...verticalStackCellStyle , fontWeight: 'bold', fontSize: '10px', } }>
                    <span>WORKER NAME</span>
                    <span>ADDRESS</span>
                    <span>LAST FOUR DIGITS OF SSN</span>
                </div>
                <div style={ { ...verticalStackCellStyle , fontWeight: 'bold', fontSize: '10px', } }>
                    <span>TRADE CLASSIFICATION</span>
                    <span>UNION LOCAL #</span>
                    <span>JOURNEY PERSON OR APPRENTICE</span>
                    <span>(NYS DOL REGISTERED)</span>
                </div>
                <div style={ { ...verticalStackCellStyle2 , fontWeight: 'bold', fontSize: '10px', } }>
                    <span>T</span>
                    <span>I</span>
                    <span>M</span>
                    <span>E</span>
                </div>
            </div>

            {/*START HEADER DATA ORIENTED SECTION: THIS NEEDS TO BE CREATED DYNAMICALLY USING AN END DATE GOING 7 DAYS BACK  */}
            <div style={ tableRowSection2Style }>
                <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '14px', height: '20%', } }>
                    <span>THIS PROJECT, CONTRACT OR BUILDING</span>
                </div>
                <div style={ {...dateHeaderStyle, height: '80%' }}>
                    <div style= { { width: '100%' } }>
                        <div style= { { ...verticalStackCellStyle,  height: '20%', fontWeight: 'bold', fontSize: '10px',  } }>
                            <span>DAY AND DATE</span>
                        </div>
                        <div style= { {...weekdayStyle, height: '30%' } }>
                            {weekDates.map((date, idx) => (
                                <div key={idx} style={{ ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '12px', }}>{date.weekday.replace(',', '')}</div>
                            ))}
                        </div>
                        <div style= { {...weekdayStyle, height: '30%' } }>
                            {weekDates.map((date, idx) => (
                                <div key={idx} style={{ ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '12px',  }}>{date.date}</div>
                            ))}
                        </div>
                        <div style= { { ...verticalStackCellStyle, height: '17%', fontWeight: 'bold', fontSize: '10px', } }>
                            <span>HOURS WORKED EACH DAY</span>
                        </div>
                    </div>
                    <div style= { { ...verticalStackCellStyle2, fontWeight: 'bold', fontSize: '10px',  } }>
                        <span>TOTAL HOURS</span>
                    </div>
                    <div style= { { ...verticalStackCellStyle2, fontWeight: 'bold', fontSize: '10px',  } }>
                        <span>HOURLY RATE OF PAY</span>
                    </div>
                    <div style= { { ...verticalStackCellStyle2, fontWeight: 'bold', fontSize: '10px',  } }>
                        <span>GROSS PAY (THIS PROJECT)</span>
                    </div>
                </div>
            </div>
            {/* END HEADER DATA ORIENTED SECTION:  */}

            <div style={ tableRowSection2Style }>
                <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '14px', height: '20%', } }>
                    <span>ALL WORK (PUBLIC AND PRIVATE)</span>
                </div>

                <div style={ { display: 'grid', gridTemplateColumns: '3fr 4fr', height: '15%'} }>
                        <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '10px', } }>
                            <span>WAGES</span>
                        </div>
                        <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '10px', } }>
                            <span>BONA FIDE FRINGE BENEFITS</span>
                        </div>
                </div>
                <div style={ { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 3fr', height: '65%'} }>
                    <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '10px', } }>
                        <span>TOTAL GROSS PAY (ALL WORK)</span>
                    </div>
                    <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '10px', } }>
                        <span>WITHHLDINGS & DEDUCTIONS</span>
                    </div>
                    <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '10px', } }>
                        <span>NET PAY</span>
                    </div>
                    <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '10px', } }>
                        <span>HOURLY CONTRIBUTIONS TO BENEFIT FUNDS OR INDIVIDUAL ACCOUNTS</span>
                    </div>
                    <div style= { { width: '100%', textAlign: 'center', height: '100%' } }>
                        <div style= { { ...verticalStackCellStyle, height: '20%', fontWeight: 'bold', fontSize: '10px', } }>
                            <span>ALL OTHER BONA FIDE FRINGE BENEFITS</span>
                        </div>
                            <div style={ { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', height: '80%' } }>
                                <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '10px', } }>
                                    <span>EMPLOYER PROJECTED ANNUAL COST</span>
                                </div>
                                <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '10px', }}>
                                    <span>EMPLOYEE PROJECTED ANNUAL HOURS</span>
                                </div>
                                <div style= { { ...verticalStackCellStyle, fontWeight: 'bold', fontSize: '10px', } }>
                                    <span>ANNUALIZED HOURLY RATE</span>
                                </div>
                            </div>
                        </div>
                </div>
                
            </div>
        </div>
    );
};
  
export default memo(DateHeader);