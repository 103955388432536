import React, { memo } from 'react';

import { 
    HeaderRowProps,
} from './dtos/HeaderRow.dto'

const LabeledValue: React.FC<HeaderRowProps> = ({ columnStyle, label, labelStyle, valueStyle, value }) => {
  return (
    <div style={{ ...columnStyle }}>
      <label style={{ paddingLeft: '5px', ...labelStyle }}>{label}</label>
      <div style={{ paddingLeft: '5px', ...valueStyle }}>
            {value}
        </div>
    </div>
  );
};

const Title: React.FC<Pick<HeaderRowProps, 'columnStyle' | 'label' | 'labelStyle'>> = ({ columnStyle, label, labelStyle }) => {
  return (
    <div style={{ ...columnStyle }}>
        <div style={{ ...labelStyle }}>{label}</div>
    </div>
  )
};

const HeaderRow: React.FC<{ rows: HeaderRowProps[]; rowStyle?: React.CSSProperties }> = ({ rows, rowStyle }) => {
  return (
    <div style={{ ...rowStyle }}>
      {rows.map((row, index) => {
        const Component = row.type === 'title' ? Title : LabeledValue;
        return <Component key={index} {...row} />
      })}
    </div>
  );
};

export default memo(HeaderRow);