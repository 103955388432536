import React from 'react';
import {
  // BrowserRouter as Router,
  HashRouter as Router, // change back to browser when no longer embedding
  Routes,
  Route
} from 'react-router-dom';
import MainPage from './pages/main';
import ReviewPage from './pages/review';

function App() {
  return (
    <Router>
      <div className="main-theme">
        <Routes>
          <Route path="/" element={<ReviewPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
