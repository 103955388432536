import React, { useRef, useState, useEffect } from 'react';
import VirtualizedForms from '../forms/421a/421aVirtualized';
import { DataRow } from '../forms/421a/dtos/TimesheetRow.dto';
import { HeaderData } from '../forms/421a/dtos/HeaderRow.dto';
import { SelectedChildNodes } from '../general/NestedSelectionList';
import './styles/report-panel.css'

interface RightPanelProps {
  selectedChildNodes: Set<SelectedChildNodes>;
  onRenderingComplete: () => void;
  isRunningReport: boolean;
  isProcessingDocusign: boolean;
  isPdfPrinting: boolean;
}

interface ReportData {
  header: HeaderData;
  timesheets: DataRow[];
}

const ReportPanel: React.FC<RightPanelProps> = ({ selectedChildNodes, onRenderingComplete, isRunningReport, isProcessingDocusign, isPdfPrinting, }) => {
  const [data, setData] = useState<ReportData[]>([]);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedChildNodes.size === 0) {
        onRenderingComplete();
        return;
      }

      setIsFetchingData(true);
      const requests = Array.from(selectedChildNodes).map(({ additionalData }) =>
        fetch(`/api/form/sub-contractor-report-timesheet-data?subContractorId=${additionalData.sub_contractor_id}&endDate=${additionalData.date}`)
      );

      try {
        const responses = await Promise.all(requests);
        const dataPromises = responses.map(res => res.json());
        const allData = await Promise.all(dataPromises);

        const transformedData = allData.map((report) => ({
          header: report.header,
          timesheets: report.timesheets
        }));

        transformedData.sort((a, b) => new Date(a.header.weekEndingDate).getTime() - new Date(b.header.weekEndingDate).getTime());
        setData(transformedData);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsFetchingData(false);
      }
    };

    fetchData();
  }, [selectedChildNodes]);


  return (
    <div ref={formRef}>
        {(isRunningReport || isFetchingData || isPdfPrinting || isProcessingDocusign) && (
          <div className="loading-overlay">
            <div className="loading-text">
              {isRunningReport || isFetchingData ? 'Loading...' : isPdfPrinting ? 'Preparing PDF export...' : 'Processing Docusign Request...'}
            </div>
          </div>
        )}

      {selectedChildNodes.size > 0 && !isFetchingData && !isPdfPrinting && !isProcessingDocusign && (
        <div ref={formRef}>
          <VirtualizedForms reports={data} onRenderingComplete={onRenderingComplete} />
        </div>
      )}
    </div>
  );
};

export default ReportPanel;
