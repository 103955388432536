import React, { useState, useEffect, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import './styles/NestedSelectionList.css'

export interface TreeDataNode {
  title: string;
  key: string;
  children?: TreeDataNode[];
  additionalData?: any;
}

export interface SelectedChildNodes {
  key: string;
  additionalData?: any;
}

export interface NestedListProps {
  data: TreeDataNode[];
  singleParent?: boolean;
  keySeparator?: string;
  onSelectionChange?: (selectedNodes: Set<string>, selectedChildNodes: Set<SelectedChildNodes>) => void;
}

const NestedSelectionList: React.FC<NestedListProps> = ({ data, singleParent, keySeparator, onSelectionChange, }) => {
  const [nodes, setNodes] = useState<TreeDataNode[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());
  const [selectedNodes, setSelectedNodes] = useState<Set<string>>(new Set());
  const [selectedChildNodes, setSelectedChildNodes] = useState<Set<SelectedChildNodes>>(new Set());
  const [partialNodes, setPartialNodes] = useState<Set<string>>(new Set());

  const keySep = keySeparator ? keySeparator : '-'

  useEffect(() => {
    setNodes(data);
  }, [data]);

  const toggleExpandNode = (key: string) => {
    const newExpandedNodes = new Set(expandedNodes);
    if (newExpandedNodes.has(key)) {
      newExpandedNodes.delete(key);
    } else {
      newExpandedNodes.add(key);
    }
    setExpandedNodes(newExpandedNodes);
  };

  const handleCheckboxChange = (node: TreeDataNode) => {
    const newSelectedNodes = new Set<string>(selectedNodes);
    const newSelectedChildNodes = new Set<SelectedChildNodes>(selectedChildNodes);
    const newPartialNodes = new Set<string>(partialNodes);

    if (singleParent && Array.from(selectedNodes).some(selectedNode => getTopParentKey(node.key) !== getTopParentKey(selectedNode))) {
      if (window.confirm('Proceeding will clear the existing selection. Do you want to continue?')) {
          newSelectedNodes.clear();
          newSelectedChildNodes.clear();
          newPartialNodes.clear();
        } else {
          return;
        }
    }

    if (newSelectedNodes.has(node.key)) {
      handleDeselectNode(node, newSelectedNodes, newSelectedChildNodes, newPartialNodes);
      if (node.children) {
        node.children.forEach(child => handleDeselectNode(child, newSelectedNodes, newSelectedChildNodes, newPartialNodes));
      } 

    } else {
      handleSelectNode(node, newSelectedNodes, newSelectedChildNodes, newPartialNodes);
      if (node.children) {
        node.children.forEach(child => handleSelectNode(child, newSelectedNodes, newSelectedChildNodes, newPartialNodes));
      }
    }
    
    updateParentSelection(node.key, newSelectedNodes, newPartialNodes);

    setSelectedNodes(newSelectedNodes);
    setSelectedChildNodes(newSelectedChildNodes);
    setPartialNodes(newPartialNodes);

    if (onSelectionChange) {
      onSelectionChange(newSelectedNodes, newSelectedChildNodes);
    }
  };

  const handleSelectNode = (node: TreeDataNode, selectedNodes: Set<string>, selectedChildNodes: Set<SelectedChildNodes>, partialNodes: Set<String>,) => {
    selectedNodes.add(node.key);
    partialNodes.delete(node.key);
    if (node.children) {
      node.children.forEach(child => handleSelectNode(child, selectedNodes, selectedChildNodes, partialNodes));
    } else {
      selectedChildNodes.add(node);
    }
  };

  const handleDeselectNode = (node: TreeDataNode, selectedNodes: Set<string>, selectedChildNodes: Set<SelectedChildNodes>, partialNodes: Set<String>,) => {
    selectedNodes.delete(node.key);
    if (node.children) {
      node.children.forEach(child => handleDeselectNode(child, selectedNodes, selectedChildNodes, partialNodes));
    } else {
      selectedChildNodes.delete(node);
    }
  };

  const updateParentSelection = (key: string, selectedNodes: Set<string>, partialNodes: Set<string>) => {
    const parentKey = getParentKey(key);
    if (!parentKey) return;

    const parentNode = findNode(nodes, parentKey);
    if (!parentNode) return;

    const allChildrenSelected = parentNode.children?.every(child => selectedNodes.has(child.key));
    const anyChildSelected = parentNode.children?.some(child => selectedNodes.has(child.key) || partialNodes.has(child.key));

    if (allChildrenSelected) {
      selectedNodes.add(parentKey);
      partialNodes.delete(parentKey);
    } else if (anyChildSelected) {
      selectedNodes.delete(parentKey);
      partialNodes.add(parentKey);
    } else {
      selectedNodes.delete(parentKey);
      partialNodes.delete(parentKey);
    }

    if (parentKey !== key) {
      updateParentSelection(parentKey, selectedNodes, partialNodes);
    }
  };

  const getParentKey = (key: string): string | null => {
    const parts = key.split(keySep);
    if (parts.length <= 1) return key;
    parts.pop();
    return parts.join(keySep);
  };

  const getTopParentKey = (key: string): string | null => {
    const parts = key.split(keySep);
    if (parts.length <= 1) return key;
    return parts[0];
  };

  const findNode = (nodes: TreeDataNode[], key: string): TreeDataNode | undefined => {
    for (const node of nodes) {
      if (node.key === key) return node;
      if (node.children) {
        const found = findNode(node.children, key);
        if (found) return found;
      }
    }
    return undefined;
  };

  const renderTreeNodes = (nodes: TreeDataNode[], level: number = 0) => {
    return nodes.map(node => {

      return (
        <div key={node.key} className="node" style={{ paddingLeft: `${level * 16}px` }}>
          <div className="node-header">
            {node.children && (
              <button className="toggle-button" onClick={() => toggleExpandNode(node.key)}>
                <FontAwesomeIcon icon={expandedNodes.has(node.key) ? faMinus : faPlus} />
              </button>
            )}
            <input
              type="checkbox"
              id={`checkbox-${node.key}`}
              checked={selectedNodes.has(node.key)}
              className={partialNodes.has(node.key) ? 'partial-selection' : 'general-selection'}
              onChange={() => handleCheckboxChange(node)}
            />
            <label htmlFor={`checkbox-${node.key}`}></label>
            <span>{node.title}</span>
          </div>
          {expandedNodes.has(node.key) && node.children && (
            <div className="nested-menu">{renderTreeNodes(node.children, level + 1)}</div>
          )}
        </div>
      );
    });
  };

  return <div className="nested-list">{renderTreeNodes(nodes)}</div>;
};

export default NestedSelectionList;
