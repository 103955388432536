import React, { useState, useEffect, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import Form421A from './421a';
import { HeaderData } from './dtos/HeaderRow.dto';
import { DataRow } from './dtos/TimesheetRow.dto';

interface VirtualizedFormsProps {
  reports: { header: HeaderData; timesheets: DataRow[] }[];
  onRenderingComplete: () => void;
}

const getItemSize = (index: number, reports: { header: HeaderData; timesheets: DataRow[] }[]): number => {
  const timesheetsPerPage = 7;
  const headerHeight = 210;
  const timesheetRowHeight = 85;
  const timesheetRows = reports[index].timesheets.length;
  const numberOfPages = Math.ceil(reports[index].timesheets.length / timesheetsPerPage)
  return (headerHeight * numberOfPages) + (timesheetRowHeight * timesheetRows) + (20 * numberOfPages);
};

const VirtualizedForms: React.FC<VirtualizedFormsProps> = ({ reports, onRenderingComplete, }) => {
  const listRef = useRef<List>(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (reports.length > 0) {
      onRenderingComplete();
    }
  }, [reports, onRenderingComplete]);

  return (
      <List
        height={windowHeight - 5}
        itemCount={reports.length}
        itemSize={(index) => getItemSize(index, reports)}
        width="100%"
        ref={listRef}
        className="list-container"
      >
        {({ index, style }) => (
          <div style={style}>
            <Form421A header={reports[index].header} timesheets={reports[index].timesheets} isDocusign={false} />
          </div>
        )}
    </List>
  );
};

export default VirtualizedForms;
