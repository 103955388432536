import { CSSProperties } from 'react';

export const reportHeaderStyle: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  height: '25px',
};

export const headerRow1Style: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '3fr 3fr 1fr 1fr 1fr',
  height: '50px',
};

export const baseHeaderCellStyle: CSSProperties = { 
  border: 'black solid 1px', 
  width: '100%', 
  height: '100%'
};

export const headerRow2Style: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '3fr 1fr 1fr 1fr 3fr',
  height: '50px',
};

export const tableRowContainer: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '3fr 3fr 4fr',
  height: '115px',
}

export const tableRowSection1Style: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '12fr 12fr 1fr',
  width: '100%',
  height: '115px'
}

export const tableRowSection2Style: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '115px'
}

export const dateHeaderStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '7fr 1fr 1fr 1fr',
}

export const weekdayStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
}

  export const cellStyle: React.CSSProperties = {
    border: '1px solid black',
    //padding: '5px',
    textAlign: 'center',
    //height: '100%'
  };
  
  // Style for cell that needs to have text elements stacked vertically.
  export const verticalStackCellStyle: React.CSSProperties = {
    ...cellStyle,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around' // This will distribute the space evenly
  };

  export const verticalStackCellStyle2: React.CSSProperties = {
    ...cellStyle,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };

  export const verticalStackCellStyleRow: React.CSSProperties = {
    border: '1px solid black',
    //padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
  };

  export const verticalStackGridStyleRow: React.CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr',
  };