import React, { memo } from 'react';
import { 
    tableRowContainer, 
    tableRowSection1Style,
    verticalStackCellStyle2 ,
    tableRowSection2Style,
    verticalStackCellStyleRow,
    dateHeaderStyle,
    weekdayStyle,
} from './styles/styles'

import { 
    DataRow,
} from './dtos/TimesheetRow.dto'

  const TimesheetRow: React.FC<{ data: DataRow }> = ({ data }) => {
    return (
        <div style={ tableRowContainer }>
        <div style={ tableRowSection1Style }>
            <div style={ { ...verticalStackCellStyleRow, padding: '5px', fontWeight: 'bold' } }>
                <span>{data.workerName}</span>
                <span>{data.address}</span>
                <span>{data.ssn}</span>
            </div>
            <div style={ { ...verticalStackCellStyleRow, padding: '5px', fontWeight: 'bold' } }>
                <span>{data.classification}</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '10px' }}>J</label>
                    <input checked={data.isJourneyman} type="checkbox" style={{ marginRight: '10px' }} readOnly/>
                    <label style={{ marginRight: '10px' }}>A</label>
                    <input checked={data.isApprentice} type="checkbox" readOnly/>
                </div>
            </div>
            <div style={ { display: 'flex', flexDirection: 'column', fontSize: '10px', height: '100%', fontWeight: 'bold' } }>
                <div style= { { ...verticalStackCellStyle2, height: '33%' } }>
                    <span>S</span>
                    <span>T</span>
                </div>
                <div style= { { ...verticalStackCellStyle2, height: '33%', fontWeight: 'bold' } }>
                    <span>O</span>
                    <span>T</span>
                </div>
                <div style= { { ...verticalStackCellStyle2, height: '34%', fontWeight: 'bold' } }>
                    <span>D</span>
                    <span>T</span>
                </div>
            </div>
        </div>

        <div style={tableRowSection2Style}>
            <div style={ {...dateHeaderStyle, height: '100%' }}>
                <div style= { weekdayStyle }>
                    {data.stHours.map((st, idx) => (
                        <div key={`st-${idx}`} style={{ display: 'flex', flexDirection: 'column', fontSize: '12px', height: '100%', fontWeight: 'bold' }}>
                        <span style={{ ...verticalStackCellStyle2, height: '33%' }}>{parseFloat(data.stHours[idx].hours).toFixed(2)}</span>
                        <span style={{ ...verticalStackCellStyle2, height: '33%' }}>{parseFloat(data.otHours[idx].hours).toFixed(2)}</span>
                        <span style={{ ...verticalStackCellStyle2, height: '34%' }}>{parseFloat(data.dtHours[idx].hours).toFixed(2)}</span>
                        </div>
                    ))}
                </div>
            {/* Total hours, rates, and other data */}
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '12px', height: '100%', fontWeight: 'bold' }}>
                <span style={{ ...verticalStackCellStyle2, height: '33%' }}>{data.stHours.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.hours), 0).toFixed(2)}</span>
                <span style={{ ...verticalStackCellStyle2, height: '33%' }}>{data.otHours.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.hours), 0).toFixed(2)}</span>
                <span style={{ ...verticalStackCellStyle2, height: '34%' }}>{data.dtHours.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.hours), 0).toFixed(2)}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '12px', height: '100%', fontWeight: 'bold' }}>
                <span style={{ ...verticalStackCellStyle2, height: '33%' }}>{parseFloat(data.rates.stRate).toFixed(2)}</span>
                <span style={{ ...verticalStackCellStyle2, height: '33%' }}>{parseFloat(data.rates.otRate).toFixed(2)}</span>
                <span style={{ ...verticalStackCellStyle2, height: '34%' }}>{parseFloat(data.rates.dtRate).toFixed(2)}</span>
            </div>
            <span style={{...verticalStackCellStyle2, fontSize: '12px', fontWeight: 'bold'}}>{parseFloat(data.grossPay).toFixed(2)}</span>
            </div>
        </div>
        
        <div style={ tableRowSection2Style }>
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', fontSize: '12px', height: '100%', fontWeight: 'bold'}}>
                <span style={{...verticalStackCellStyle2, height: '100%'}}>{data.totalGrossPay}</span>
                <span style={{...verticalStackCellStyle2, height: '100%'}}>{data.withholdings}</span>
                <span style={{...verticalStackCellStyle2, height: '100%'}}>{data.netPay}</span>
                <span style={{...verticalStackCellStyle2, height: '100%'}}>{data.hourlyContribution}</span>
                <span style={{...verticalStackCellStyle2, height: '100%'}}>{data.employerProjectedCost}</span>
                <span style={{...verticalStackCellStyle2, height: '100%'}}>{data.employeeProjectedHours}</span>
                <span style={{...verticalStackCellStyle2, height: '100%'}}>{data.annualizedHourlyRate}</span>
            </div>
        </div>
    </div>
    );
  };

  export default memo(TimesheetRow);