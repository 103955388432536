import React, { useState, useEffect } from 'react';
import NestedSelectionList from '../general/NestedSelectionList'
import { TreeDataNode, SelectedChildNodes, } from '../general/NestedSelectionList'
import './styles/selection-panel.css'

interface ReportDate {
  date: string;
  status: string;
}

interface MonthlyReport {
  month: string;
  year: string;
  dates: ReportDate[];
}

interface Subcontractor {
  sub_contractor_id: number;
  name: string;
  disclaimer_id: number;
  email: string,
  manager_name: string,
  reports: MonthlyReport[];
}

interface LeftPanelProps {
  onSelectionChange: (selectedNodes: Set<string>, selectedChildNodes: Set<SelectedChildNodes>) => void;
  onRunReport: () => void;
  onClearSelections: () => void;
  handlePrint: () => void;
  handleDocusign: () => void;
  isRunningReport: boolean;
  selectedChildNodes: Set<SelectedChildNodes>;
}

const keySep = '|#|';

const fetchSubcontractors = async (): Promise<Subcontractor[]> => {
  const response = await fetch('/api/form/all-report-dates');
  const data = await response.json();
  return data;
};

const formatTreeData = (data: Subcontractor[]): TreeDataNode[] => {
  const subcontractorMap: { [name: string]: TreeDataNode } = {};

  data.forEach((report) => {
    const { name, reports } = report;

    if (!subcontractorMap[name]) {
      subcontractorMap[name] = { title: name, key: name, children: [] };
    }

    const subcontractor = subcontractorMap[name];

    reports.forEach((rpt) => {
      const { month, year, dates } = rpt;
      const monthYearKey = `${name}${keySep}${month}${year}`;

      let monthlyReport = subcontractor.children!.find(
        (child) => child.key === monthYearKey
      );

      if (!monthlyReport) {
        monthlyReport = { title: `${month} ${year}`, key: monthYearKey, children: [] };
        subcontractor.children!.push(monthlyReport);
      }

      dates.forEach((reportDate) => {
        const { date, status } = reportDate;
        monthlyReport!.children!.push({
          title: date,
          key: `${monthYearKey}${keySep}${date}`,
          additionalData: {
            status: status, 
            sub_contractor_name: name, 
            sub_contractor_id: report.sub_contractor_id, 
            month: month, 
            year: year, 
            date: date, 
            disclaimer_id: report.disclaimer_id,
            email: report.email,
            manager_name: report.manager_name,
          },
        });
      });
    });
  });

  return Object.values(subcontractorMap);
};

const SelectionPanel: React.FC<LeftPanelProps> = ( { onSelectionChange, onRunReport, onClearSelections, handlePrint, handleDocusign, isRunningReport, selectedChildNodes: selectedChildNodes, } ) => {
  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);

  useEffect(() => {
    fetchSubcontractors().then(data => {
      setTreeData(formatTreeData(data));
    });
  }, []);

  return (
    <div className="selection-panel">
      <div className="header-section">
        {/* Content for the header will be populated later */}
      </div>
      <div className="list-wrapper">
        <div className="list-section">
          {treeData && (
            <NestedSelectionList
              data={treeData}
              singleParent={true}
              keySeparator={keySep}
              onSelectionChange={onSelectionChange}
            />
          )}
        </div>
        <div className="button-section">
          <button onClick={onClearSelections} disabled={ isRunningReport || selectedChildNodes.size === 0 }>Clear All Selections</button>
          <button onClick={onRunReport} disabled={ isRunningReport || selectedChildNodes.size === 0 }>Run Report</button>
          <button onClick={handlePrint} disabled={ isRunningReport || selectedChildNodes.size === 0 }>Print Report</button>
          <button onClick={handleDocusign} disabled={ isRunningReport || selectedChildNodes.size === 0 }>Send to DocuSign</button>
        </div>
      </div>
    </div>
  );
};

export default SelectionPanel;
