import React, { useState } from 'react';
import './styles/review.css';
import SelectionPanel from '../components/panels/SelectionPanel';
import ReportPanel from '../components/panels/ReportPanel';
import { SelectedChildNodes } from '../components/general/NestedSelectionList';

const ReviewPage: React.FC = () => {
    const [selectedNodes, setSelectedNodes] = useState<Set<string>>(new Set());
    const [selectedChildNodes, setSelectedChildNodes] = useState<Set<SelectedChildNodes>>(new Set());
    const [isRunningReport, setIsRunningReport] = useState<boolean>(false);
    const [reportData, setReportData] = useState<Set<SelectedChildNodes>>(new Set());
    const [isProcessingDocusign, setIsProcessingDocusign] = useState<boolean>(false);
    const [isPdfPrinting, setIsPdfPrinting] = useState<boolean>(false);

    const handleSelectionChange = (selectedNodes: Set<string>, selectedChildNodes: Set<SelectedChildNodes>) => {
        setSelectedNodes(selectedNodes);
        setSelectedChildNodes(selectedChildNodes);
    };

    const handleRunReport = async () => {
        console.log('Running Report...')
        setIsRunningReport(true);
        setReportData(new Set(selectedChildNodes));
    };

    const handleRenderingComplete = () => {
        setIsRunningReport(false);
    };

    // const handlePrintingComplete = () => {
    //     setIsPdfPrinting(false);
    // };

    // const handleDocusignComplete = () => {
    //     setIsProcessingDocusign(false);
    // };

    const handleClearSelections = () => {
        setSelectedNodes(new Set());
        setSelectedChildNodes(new Set());
        setReportData(new Set());
    };

    const handlePrint = async () => {
        setIsPdfPrinting(true);
        const printData: { sub_contractor_id: number; date: string; }[] = Array.from(selectedChildNodes).map(item => ({
            sub_contractor_id: parseInt(item.additionalData.sub_contractor_id, 10),
            date: item.additionalData.date
        }));
        const response = await fetch('/api/form/generate-document', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ selectedChildNodes: printData }),
        });

        if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const disposition = response.headers.get('Content-Disposition');
        let filename = '421a_report.pdf';
        if (disposition && disposition.includes('attachment')) {
            const filenameMatch = disposition.match(/filename="?([^"]+)"?/);
            if (filenameMatch && filenameMatch.length > 1) {
                filename = filenameMatch[1];
            }
        }

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setIsPdfPrinting(false);
        } else {
        console.error('Failed to generate document', await response.text());
        }
    };

    const handleDocusign = async () => {
        setIsProcessingDocusign(true);
        const printData: { sub_contractor_name: string; sub_contractor_id: number; date: string; disclaimer_id: string; month: number; year: number,}[] = Array.from(selectedChildNodes).map(item => ({
            sub_contractor_name: item.additionalData.sub_contractor_name,
            sub_contractor_id: parseInt(item.additionalData.sub_contractor_id, 10),
            date: item.additionalData.date,
            disclaimer_id: item.additionalData.disclaimer_id,
            month: item.additionalData.month,
            year: item.additionalData.year,
        }));

        const response = await fetch('/api/docusign/send-document', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ selectedChildNodes: printData }),
        });

        if (response.ok) {
            console.log('Successfully sent to DocuSign.');
        } else {
            console.error('Failed to generate document', await response.text());
        }
        setIsProcessingDocusign(false);
    };

    return (
        <div className="app">
            <div className="left-panel">
                <ReportPanel 
                    selectedChildNodes={reportData} 
                    onRenderingComplete={handleRenderingComplete}
                    isRunningReport={isRunningReport}
                    isPdfPrinting={isPdfPrinting}
                    isProcessingDocusign={isProcessingDocusign}
                />
            </div>
            <div className="right-panel">
                <SelectionPanel 
                    onSelectionChange={handleSelectionChange} 
                    onRunReport={handleRunReport} 
                    onClearSelections={handleClearSelections} 
                    handlePrint={handlePrint} 
                    handleDocusign={handleDocusign} 
                    isRunningReport={isRunningReport} 
                    selectedChildNodes={selectedChildNodes}
                />
            </div>
        </div>
    );
};

export default ReviewPage;
